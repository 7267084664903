import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";
import Icon from "@mdi/react";
import { mdiLockOutline } from "@mdi/js";

import { Grid, LoadingButton, TextField } from "../../../collections/Imports";

import Help from "../../../assets/images/help.svg";

import {
  LogoImg,
  Tooltip,
  Divider,
  CommonDrawer,
  Yup,
  useFormik,
  useState,
} from "../../../collections/Imports";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changePasswordService } from "helpers/credmudra/dashBoard-insights";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  var userEmail = localStorage.getItem("username");
  var userName = "";

  if (userEmail) {
    var match = userEmail.match(/^(.+)@/);
    if (match) {
      userName = match[1].charAt(0).toUpperCase() + match[1].slice(1);
    } else {
      console.log("userEmail Error");
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    enqueueSnackbar("Logout Successfully", { variant: "success" });
  };
  const dsaId = localStorage.getItem("dsaId");

  //NOTE: CHANGE PASSWORD

  const validationSchema1 = Yup.object().shape({
    oldPassword: Yup.string()
      .trim("Password cannot include leading and trailing spaces")
      .strict(true)
      .required("Password is required"),
    newPassword: Yup.string()
      .trim("Password cannot include leading and trailing spaces")
      .strict(true)
      .min(4, "Password must be at least 4 characters long")
      .required("Password is required"),
  });

  const formik1 = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: validationSchema1,
    onSubmit: async (values) => {
      setLoading(true);
      const reqData = {
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword,
      };
      const response = await changePasswordService(reqData);

      if (response.error) {
        setLoading(false);
        enqueueSnackbar(`${response?.data}`, { variant: "error" });
      } else {
        setLoading(false);
        handleCloseChangePassword();
        localStorage.clear();
        navigate("/login");
        enqueueSnackbar("Password Changed Successfully, Please Login", {
          variant: "success",
        });
      }
    },
  });

  const handleChangePswrd = () => {
    setOpenChangePassword(true);
    setAnchorEl(null);
  };
  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
    formik1.resetForm();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fff",
          height: "48px",
          boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.16)",
          zIndex: 99999,
        }}
        elevation={0}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              direction={"row"}
              sx={{ display: "flex", alignItems: "center", pl: "8px" }}
            >
              <img src={LogoImg} alt="Credmudra DSA" className="logo" />
              <Typography
                className="logo-text"
                sx={{
                  "@media (max-width: 477px)": {
                    display: "none",
                  },
                }}
              >
                Credmudra DSA
              </Typography>
            </Box>
          </Box>
          <Stack direction={"row"}>
            {dsaId && (
              <Box>
                <Button
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid #DDE1E6",
                    background: "#FFF",
                    color: "#EE9329",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    marginRight: "1rem",
                    marginTop: "0.5rem",
                  }}
                >
                  DSA ID : {dsaId}
                </Button>
              </Box>
            )}

            <IconButton
              onClick={handleMenu}
              sx={{
                mr: 5,
              }}
            >
              {userEmail && userEmail[0] && (
                <Avatar
                  sx={{
                    bgcolor: "#0e356c",
                    color: "#fff",
                    fontWeight: "500px",
                    width: 32,
                    height: 32,
                    textTransform: "capitalize",
                  }}
                >
                  {userEmail[0]}
                </Avatar>
              )}
            </IconButton>
          </Stack>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ top: "30px" }}
          >
            <MenuItem>
              <Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  pb={2}
                >
                  {userEmail && userEmail[0] && (
                    <Avatar
                      sx={{
                        bgcolor: "#0e356c",
                        color: "#fff",
                        width: 32,
                        height: 32,
                        textTransform: "capitalize",
                      }}
                    >
                      {userEmail[0]}
                    </Avatar>
                  )}
                  <Box mx={2}>
                    <Box
                      pb={0}
                      color={"#172b4d"}
                      fontWeight={"bold"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {userName}
                    </Box>
                    <Box>{userEmail || ""}</Box>
                  </Box>
                </Box>
                <Divider />
                <Box display={"flex"} alignItems={"center"} pt={1}>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    onClick={handleChangePswrd}
                  >
                    <Icon path={mdiLockOutline} size={1} />
                    Change password
                  </Button>
                </Box>
                <Box display={"flex"} alignItems={"center"} pt={1}>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    onClick={handleLogout}
                  >
                    <LogoutIcon
                      fontSize={"medium"}
                      style={{ paddingRight: "5px" }}
                    />{" "}
                    Logout
                  </Button>
                </Box>
              </Box>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <CommonDrawer
        open={openChangePassword}
        onClose={handleCloseChangePassword}
      >
        <form onSubmit={formik1.handleSubmit}>
          <Box className="filter-header">
            <Typography className="filter-title">Change Password</Typography>
          </Box>
          <Divider />
          <Box width={"100%"}>
            <Grid container spacing={2} marginTop={2} paddingX={1}>
              <Grid item xs={12}>
                <Box className="custom-textfield">
                  <Typography mb={1}>Old Password</Typography>
                  <TextField
                    name="oldPassword"
                    type={showOldPassword ? "text" : "password"}
                    value={formik1?.values?.oldPassword}
                    onChange={(e) =>
                      formik1.setFieldValue("oldPassword", e.target.value)
                    }
                    error={
                      formik1.touched.oldPassword && formik1.errors.oldPassword
                    }
                    helperText={
                      formik1.touched.oldPassword && formik1.errors.oldPassword
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ p: 1 }}>
                          <IconButton
                            onClick={() => setShowOldPassword(!showOldPassword)}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="custom-textfield">
                  <Typography mb={1}>New Password</Typography>
                  <TextField
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    value={formik1?.values?.newPassword}
                    onChange={(e) =>
                      formik1.setFieldValue("newPassword", e.target.value)
                    }
                    error={
                      formik1.touched.newPassword && formik1.errors.newPassword
                    }
                    helperText={
                      formik1.touched.newPassword && formik1.errors.newPassword
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ p: 1 }}>
                          <IconButton
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Divider />
          <Box p={2}>
            <Grid
              container
              spacing={2}
              display={"flex"}
              justifyContent="flex-end"
              align-items="center"
            >
              <Grid
                item
                sm={4}
                xs={8}
                mt={2}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <LoadingButton
                  loading={loading}
                  loadingPosition="end"
                  type="submit"
                  variant="contained"
                  className="apply-button"
                  sx={{ width: "400px" }}
                >
                  Change Password
                </LoadingButton>
              </Grid>
              <Grid item sm={2} mt={2} xs={4}>
                <Button
                  onClick={handleCloseChangePassword}
                  variant="outlined"
                  className="cancel-button"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </CommonDrawer>
    </Box>
  );
};

export default Header;
