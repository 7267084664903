import React, { useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "../../collections/Imports";
import "./style.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function KpiCard(props) {
  const {
    title,
    icon,
    count,
    isDropdown,
    cmacceptObj: acceptObj,
    cmAcceptedIcon,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const [isArrow, setIsArrow] = useState(false);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsArrow(true);
  };

  const handleMenuItemClick = (status) => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsArrow(false);
  };

  return (
    <Box className="card-bg">
      <Box
        display="flex"
        justifyContent="space-between"
        gap={"16px"}
        alignItems={"center"}
      >
        <Box className="icon">{icon}</Box>
        <Box>
          <Typography variant="h4" className="title">
            {title}
          </Typography>
          <Typography variant="h2" className="count" pt={"8px"} pl={"4px"}>
            {count}
          </Typography>
        </Box>
        {isDropdown && (
          <Box>
            {isArrow ? (
              <IconButton
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleDropdownClick}
              >
                <ArrowDropUpIcon />
              </IconButton>
            ) : (
              <IconButton
                size={"small"}
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleDropdownClick}
              >
                <ArrowDropDownIcon />
              </IconButton>
            )}
            <Menu
              className="custom-dropdown"
              id="dropdown-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("CM Accepted")}>
                {acceptObj?.length > 0 &&
                  acceptObj?.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      gap={"16px"}
                      alignItems={"center"}
                    >
                      <Box className="icon">{cmAcceptedIcon}</Box>
                      <Box>
                        <Typography variant="h4" className="title">
                          {item.status === "cmAccepted" && (
                            <span>CM Accepted</span>
                          )}
                        </Typography>
                        <Typography
                          variant="h2"
                          className="count"
                          pt={"8px"}
                          pl={"4px"}
                        >
                          {item?.count}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default KpiCard;
