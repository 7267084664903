import React from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";

function CommonReactDataGrid({
  data,
  columns,
  setGridRef,
  isFullscreen,
  filterValue,
  groups,
  onEditComplete,
}) {
  return (
    <ReactDataGrid
      handle={setGridRef}
      rowHeight={40}
      theme="blue-light"
      idProperty="uniqueId"
      groups={groups}
      dataSource={data}
      columns={columns}
      onEditComplete={onEditComplete}
      editable={false}
      pagination
      defaultFilterValue={filterValue}
      showColumnMenuTool={false}
      rowExpandHeight={400}
      pageSizes={[1000, 2000, 3000, 4000, 5000, 10000]}
      defaultLimit={1000}
      defaultShowEmptyRows
      cellSelectionByIndex={true}
      nativeScroll
      style={{ height: isFullscreen ? "90vh" : 550, width: "100%" }}
    />
  );
}

export default CommonReactDataGrid;
