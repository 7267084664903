import { Drawer } from "../../collections/Imports";

const CommonDrawer = (props) => {
  const { anchor, open, onClose, children } = props;
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      className="drawar-style"
      sx={{
        "& .MuiDrawer-paperAnchorRight": {
          margin: 2,
          height: "98vh",
          minWidth: "500px",
          maxWidth: "500px",
          width: "28%",
          borderRadius: "8px",
          border: "1px solid #DFE3E7",
          background: "#FFF",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          "@media (max-width: 767px)": {
            minWidth: "95%",
            margin: 1,
          },
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default CommonDrawer;
