const apiEndPointsConfig = {
  login: "/lds/public/agent-login",

  //parteners-api

  getkpiStats: "/lds/partners/get-kpis",
  getTableStats: "/lds/partners/get-stats",
  getUtmSource: "/lds/partners/get-utms",

  GET_KPI_STATS: "/lds/partners/get-kpis",
  GET_TABLE_STATS: "/lds/partners/get-stats",
  GET_TAB_DATA: "/lds/partners/get-tab-data",
  GET_TOTAL_DATA: "/lds/partners/get-total-data",
  GET_NON_ELIGIBLE_DATA: "/lds/partners/get-non-eligible-data",
  GET_CM_STATUS_LEADS_DATA: "/lds/partners/get-cm-accept-data",

  //

  GET_KPI_DSA: "/lds/dsa/get-kpi",
  GET_TABLE_DSA: "/lds/dsa/get-leads",
  GET_LEAD_LOGS: "/lds/dsa/get-lead-log-detail",
  SEND_SMS: "/lds/dsa/send-sms",
  CHANGE_PASSWORD: "lds/dsa/change-password",

  GET_LINK: "lds/dsa/get-link",
};

export default apiEndPointsConfig;
