import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dsaKpi: [],
  dashboardFilter: null,
};

export const dsaKpiSlice = createSlice({
  name: "dsaKpi",
  initialState,
  reducers: {
    getDsaKpi: (state, action) => {
      state.dsaKpi = action.payload;
    },
  },
});

export const dsaKpiAction = dsaKpiSlice.actions;
