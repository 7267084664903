import React, { useEffect, useState } from "react";
import {
  DateRangePicker,
  DateRange,
  createStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Stack } from "../../collections/Imports";
import "./style.css";

const CommonDateRangePicker = ({ staticRanges, maxDate, ranges, onChange }) => {
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 500);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    // <Stack marginTop={2} spacing={2} className="date-picker">
    //   <DateRangePicker
    //     staticRanges={createStaticRanges(staticRanges)}
    //     maxDate={maxDate}
    //     ranges={ranges}
    //     onChange={(item) => onChange(item)}
    //     sx={{
    //       width: "100px",
    //     }}
    //   />

    //   <DateRange
    //     staticRanges={createStaticRanges(staticRanges)}
    //     maxDate={maxDate}
    //     ranges={ranges}
    //     editableDateInputs={true}
    //     onChange={(item) => onChange(item)}
    //     moveRangeOnFirstSelection={false}
    //     sx={{
    //       width: "100px",
    //     }}
    //   />
    // </Stack>

    <Stack marginTop={2} spacing={2} className="date-picker">
      {isResponsive ? (
        <DateRange
          staticRanges={createStaticRanges(staticRanges)}
          maxDate={maxDate}
          ranges={ranges}
          editableDateInputs={true}
          onChange={(item) => onChange(item)}
          moveRangeOnFirstSelection={false}
          sx={{
            width: "100px",
          }}
        />
      ) : null}
      {!isResponsive ? (
        <DateRangePicker
          staticRanges={createStaticRanges(staticRanges)}
          maxDate={maxDate}
          ranges={ranges}
          onChange={(item) => onChange(item)}
        />
      ) : null}
    </Stack>
  );
};

export default CommonDateRangePicker;
