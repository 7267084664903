import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from 'App';
import 'assets/scss/style.scss';
import ldsStore from './store';
import { Provider } from 'react-redux';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={ldsStore}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>
);
