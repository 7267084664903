import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pubStats: [],
  dashboardFilter: null,
  leadsListByStatus: [],
};

export const publisherTableStatsSlice = createSlice({
  name: "pubStats",
  initialState,
  reducers: {
    getPublisherTableStats: (state, action) => {
      state.pubStats = action.payload;
    },
    getDashboardFilters: (state, action) => {
      state.dashboardFilter = action.payload;
    },
    getLeadsListByStatus: (state, action) => {
      state.leadsListByStatus = action.payload;
    },
  },
});

export const publisherTableStatsAction = publisherTableStatsSlice.actions;
