import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    drawerToggleState: false
};
export const drawerToggleSlice = createSlice({
    name: 'drawerToggleSlice',
    initialState,
    reducers: {
        updateDrawerState: (state, action) => {
            state.drawerToggleState = action.payload;
        }
    }
});

export const updateDrawerStateActions = drawerToggleSlice.actions;
