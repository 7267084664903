import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leadInfo: {},
  lenderStatus: [],
};

export const dsaLeadLogStatusSlice = createSlice({
  name: "dsaleadlogs",
  initialState,
  reducers: {
    addLeadInfo: (state, action) => {
      state.leadInfo = action.payload;
    },
    addLenderStatus: (state, action) => {
      state.lenderStatus = action.payload;
    },
    updateLenderStatus: (state, action) => {
      const index = state.lenderStatus.findIndex(
        (res) => res.lenderId === action.payload.lenderId
      );
      state.lenderStatus[index].smsCount =
        state.lenderStatus[index].smsCount + 1;
    },
  },
});

export const dsaleadlogsAction = dsaLeadLogStatusSlice.actions;
