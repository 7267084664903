import { configureStore } from "@reduxjs/toolkit";

/**------------DASHBOARD----------------------------------- */
import { kpiStatsSlice } from "./dashboard/kpiStatsSlice";
import { publisherTableStatsSlice } from "./dashboard/publisherTableStatsSlice";
import { permissionsSlice } from "./permissionsState";
import { drawerToggleSlice } from "./drawerToggleButton";
import { dsaKpiSlice } from "./dsa/dsaKpiSlice";
import { dsaTableStatsSlice } from "./dsa/dsaTableSlice";
import { dsaLeadLogStatusSlice } from "./dsa/leadlogSlice";

/*-----------------------------------------------------------*/
const ldsStore = configureStore({
  reducer: {
    kpiStats: kpiStatsSlice.reducer,
    pubStats: publisherTableStatsSlice.reducer,
    permissionsSlice: permissionsSlice.reducer,
    drawerToggleSlice: drawerToggleSlice.reducer,

    //TODO: dsa reduses
    dsaKpi: dsaKpiSlice.reducer,
    dsaTableData: dsaTableStatsSlice.reducer,
    dsaleadLogs: dsaLeadLogStatusSlice.reducer,
  },
});
export default ldsStore;
