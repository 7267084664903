import apiEndPointsConfig from "helpers/apiEndPointsConfig";
import apiService from "helpers/apiService";

export const getkpiService = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_KPI_DSA, {
      data: requestData,
    });
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

export const getTableStatsServiceDsa = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_TABLE_DSA, {
      data: requestData,
    });
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

export const getLeadsStatusByCountService = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_TAB_DATA, {
      data: requestData,
    });
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};
export const getLeadsStatusByNonEligibleService = async (requestData) => {
  try {
    const response = await apiService.post(
      apiEndPointsConfig.GET_NON_ELIGIBLE_DATA,
      {
        data: requestData,
      }
    );
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};
export const getCMStatusService = async (requestData) => {
  try {
    const response = await apiService.post(
      apiEndPointsConfig.GET_CM_STATUS_LEADS_DATA,
      {
        data: requestData,
      }
    );
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};
export const getTotalDataService = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_TOTAL_DATA, {
      data: requestData,
    });
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};
export const getleadLogs = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_LEAD_LOGS, {
      data: requestData,
    });
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

export const changePasswordService = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.CHANGE_PASSWORD, {
      data: requestData,
    });
    const responseData = response?.data;
    return responseData;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const sendLenderSMS = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.SEND_SMS, {
      data: requestData,
    });
    const responseData = response?.data;
    return responseData;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const getLink = async (requestData) => {
  try {
    const response = await apiService.post(apiEndPointsConfig.GET_LINK, {
      data: requestData,
    });
    const responseData = response?.data;
    return responseData;
  } catch (error) {
    console.error(error);
    return error;
  }
};
