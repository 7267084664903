import React from "react";
import {
  Box,
  Stack,
  Typography,
  InfoOutlined,
  Button,
  Divider,
  Tooltip,
  useSelector,
  useDispatch,
} from "../../collections/Imports";
import AddIcon from "@mui/icons-material/Add";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { updateDrawerStateActions } from "store/drawerToggleButton";
const PageHeader = ({
  title,
  pageAction,
  onBack,
  backIcon,
  helpText,
  buttonAction,
  isButton,
}) => {
  const dispatch = useDispatch();
  const { drawerToggleState } = useSelector((state) => state.drawerToggleSlice);
  const openDrawer = () => {
    dispatch(updateDrawerStateActions.updateDrawerState(!drawerToggleState));
  };

  return (
    <Box
      sx={{
        pl: 2,
        "@media (max-width: 477px)": {
          marginTop: "4rem",
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <WidgetsOutlinedIcon
            onClick={openDrawer}
            sx={{
              backgroundColor: "#2A4BC0",
              display: "none",
              color: "#fff",
              mr: "18px",
              "@media (max-width: 477px)": {
                display: "block",
              },
            }}
          />
          <Button
            style={backIcon ? { minWidth: "30px" } : { display: "none" }}
            onClick={onBack}
          >
            {backIcon}
          </Button>
          <Typography
            sx={{
              color: "#202223",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.48px",
              paddingRight: 1,

              // width: '100%'
            }}
          >
            {title}
          </Typography>{" "}
          <Tooltip title={helpText}>
            <InfoOutlined sx={{ color: "#87909B" }} />
          </Tooltip>
        </Stack>
        <Box mr={2}>
          {isButton && isButton === true && (
            <Button
              sx={{
                background: "#ffffff",
                color: "#06207C",
                border: "1px solid #06207C",
                borderRadius: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",

                "&:hover": {
                  background: `#06207C`,
                  color: `#ffffff`,
                  border: "1px solid #DDE1E6",
                },
                "@media (max-width: 477px)": {
                  justifyContent: "center",
                  paddingLeft: "0.8rem",

                  background: `#06207C`,
                  color: `#ffffff`,
                  border: "1px solid #DDE1E6",
                },
              }}
              onClick={() => {
                if (buttonAction !== undefined && buttonAction !== null) {
                  buttonAction();
                }
              }}
            >
              <Typography
              // sx={{
              //   "@media (max-width: 477px)": {
              //     display: "none",
              //     marginRight: "0rem",
              //     textAlign: "center",
              //   },
              // }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 5px",
                  }}
                >
                  <AddIcon
                    fontSize="small"
                    sx={{ fontSize: "18px", marginRight: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      "@media (max-width: 477px)": {
                        display: "none",
                        marginRight: "0rem",
                        textAlign: "center",
                      },
                    }}
                  >
                    {" "}
                    Add New Lead
                  </Typography>
                </span>
              </Typography>
            </Button>
          )}
        </Box>
      </Stack>
      <Divider
        sx={{ width: "100%", pt: "18px", position: "absolute", left: 0 }}
      />
    </Box>
  );
};

export default PageHeader;
