import {
  Outlet,
  useTheme,
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Drawer,
  useSelector,
} from "../../collections/Imports";
import Header from "./Header";
import SideBar from "./Sidebar/SideBar";

const MainLayout = () => {
  const theme = useTheme();
  const {drawerToggleState}=useSelector((state)=>state.drawerToggleSlice)

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: { sm: "center" },
        justifyContent: { sm: "center" },
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        {localStorage.getItem("accessToken") ? (
          <>
            <Box>
              <Header />
            </Box>
            <Drawer
              variant="permanent"
              sx={{
                width: 200,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: 200,
                  boxSizing: "border-box",
                  zIndex: "-1",
                },
                "@media (max-width:798px)":{
                  "& .MuiDrawer-paper": {
                    display: "block", // Set the width to 100% for small screens
                    width: 58,
                  },
                },
                "@media (max-width: 477px)": {
                  "& .MuiDrawer-paper": {
                    display: drawerToggleState?"block":"none", // Set the width to 100% for small screens
                  },
                },
              }}
            >
              <Toolbar>
                <SideBar />
              </Toolbar>
            </Drawer>
          </>
        ) : null}
      </AppBar>
      <Box
      sx={{
        ...theme.typography.mainContent,
        "@media (max-width: 477px)": {
          padding:0,
          margin:0,
        },
        "@media (min-width:477px) and (max-width:798px)": {
          width: "768px",
          paddingLeft: '3rem',
        }
      }}
      >
      <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
