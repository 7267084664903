import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  useNavigate,
  ListItem,
  ListItemText,
  Tooltip,
  useSelector,
  useDispatch,
} from "../../../collections/Imports";

import { DashboardSvg } from "ui-component/svg-icons/commonSvgIcons";
import { updateDrawerStateActions } from "store/drawerToggleButton";

function SideBar() {
  const {drawerToggleState}=useSelector((state)=>state.drawerToggleSlice)
  const dispatch=useDispatch()
  const allNav = [
    {
      name: "Dashboard",
      _tag: "MenuItem",
      to: "/dashboard",
      icon: "DashboardIcon",
      permissions: ["Admin"],
    },
  ];
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleMenuItemClick = (to) => {
    setActiveMenuItem(to);
  };

  const handlePopstate = () => {
    const activeIndex = allNav.findIndex((item) => item.to === currentPath);

    if (activeIndex !== -1) {
      setIsActive(true);
      setActiveMenuItem(currentPath);
    } else {
      setIsActive(false);
      setActiveMenuItem(allNav[0]?.to);
    }
  };

  useEffect(() => {
    handlePopstate();
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [location, allNav]);

  const dashboard = [];

  allNav.forEach((item) => {
    if (["Dashboard"].includes(item.name)) {
      dashboard.push({ item });
    }
  });

  const textActiveStyle = {
    color: "#fff !important",
    fontSize: "10px",
    "& .MuiListItemText-primary": {
      color: "#fff !important",
    },
  };
  const textSimpleStyle = {
    color: "#000",
    fontSize: "10px",
  };

  const activeStyle = {
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    background: "#2A4BC0",
    color: "white",
    gap: "0px",
    borderRadius: "5px",
  };

  const simpleStyle = {
    minWidth: 0,
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    gap: "0px",
    background: "#fff",
    color: "#000",
    borderRadius: "0px",
  };

  const DefaultIcon = ({ iconName, isActive }) => {
    switch (iconName) {
      case "DashboardIcon":
        return <DashboardSvg color={isActive} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        mt: "4rem",
        backgroundColor: "#fff",
        minWidth: "100%",
      }}
    >
      <List>
        {dashboard.map((text, index) => (
          <ListItem key={text?.item?.name} disablePadding>
            <ListItemButton
              sx={
                currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle
              }
              onClick={() => {
                handleMenuItemClick(text?.item?.to);
                navigate(text?.item?.to);
                dispatch(updateDrawerStateActions.updateDrawerState(!drawerToggleState))
              }}
            >
              <Tooltip title={text?.item?.name} arrow>
                <ListItemIcon className="sideNavIcon">
                  {text.item?.icon && (
                    <ListItemIcon className="sideNavIcon">
                      <DefaultIcon
                        iconName={text.item?.icon}
                        isActive={
                          isActive && text?.item?.to === activeMenuItem
                            ? "#fff"
                            : "#000"
                        }
                      />
                    </ListItemIcon>
                  )}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={text?.item?.name}
                sx={
                  currentPath.includes(text?.item?.to)
                    ? textActiveStyle
                    : textSimpleStyle
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default SideBar;
