import React, { createContext, useContext, useState } from 'react';
const TabContext = createContext();

const TabProvider = ({ children }) => {
    const [tabValue, setTabValue] = useState(1);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return <TabContext.Provider value={{ tabValue, handleChange }}>{children}</TabContext.Provider>;
};

// Custom hook to access tabValue and handleChange
const useTabContext = () => {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error('useTabContext must be used within a TabProvider');
    }
    return context;
};

export { TabProvider, useTabContext };
