import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import ProtectedRoute from "./ProtectedRoute";
import { lazy, Navigate } from "../collections/Imports";
import NotFound from "views/notFound/NotFound";

const Dashboard = Loadable(lazy(() => import("views/dsa-dashboard")));
const LeadLogDeatils = Loadable(lazy(() => import("views/leadLogDetails")));
// const ListByStatusCount = Loadable(
//   lazy(() =>
//     import("views/new-dashboard/components/leads-list-by-status-count")
//   )
// );

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/leadstaus/:id",
      element: (
        <ProtectedRoute>
          <LeadLogDeatils />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/dashboard/status-count/:id",
    //   element: (
    //     <ProtectedRoute>
    //       <ListByStatusCount />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default MainRoutes;
