import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dsaStats: [],
  dashboardFilter: null,
  //   leadsListByStatus: [],
};

export const dsaTableStatsSlice = createSlice({
  name: "dsaStats",
  initialState,
  reducers: {
    getDsaTableStats: (state, action) => {
      state.dsaStats = action.payload;
    },
    getDashboardFilters: (state, action) => {
      state.dashboardFilter = action.payload;
    },
    // getLeadsListByStatus: (state, action) => {
    //   state.leadsListByStatus = action.payload;
    // },
  },
});

export const dsaTableStatsAction = dsaTableStatsSlice.actions;
