import { Box, Typography, React } from '../collections/Imports';

function CircularLoadingProgress() {
    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <Box className="loader"></Box>
            <Typography
                variant="h2"
                sx={{
                    margin: '0',
                    fontSize: '19px',
                    color: '#aaabad',
                    fontWeight: '500',
                    fontFamily: "'Roboto',sans-serif",
                    lineHeight: '1.2',
                    paddingTop: '2rem'
                }}
            >
                Please Wait for a moment ...
            </Typography>
        </Box>
    );
}

export default CircularLoadingProgress;
